<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-3 spark-settings-tabs">
        <billing-nav :user="user" :path="path"></billing-nav>
      </div>
      <div class="col-md-9">
        <div class="card border-0 shadow-sm">
          <div class="card-body mb-1">
            <div class="row" v-if="!user.clinic.billing_subscription.cancel_at_period_end">
              <div class="col text-center">
                <p class="alert alert-danger">Are you sure you wish to cancel? <br/> Your subscription will no longer renew on {{user.clinic.billing_subscription.current_period_end | formatDate}}.</p>
                <button class="btn btn-danger" @click="cancelSubscription">
                  <i class="fa fa-ban me-2"></i>
                  Cancel Subscription
                </button>
              </div>
            </div>

            <div class="row" v-else>
              <div class="col text-center">
                <p>
                  You have already cancelled your subscription. You can continue to use your {{user.clinic.billing_subscription.billing_product.title}} features until {{user.clinic.billing_subscription.current_period_end | formatDate}}.
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import BillingNav from "../partials/BillingNav";
export default {
  props: [],
  components: {
    BillingNav,
  },
  methods: {
    cancelSubscription(){
      var confirmed = confirm("Please click OK to confirm that you wish to cancel your subscription renewal.");
      if (confirmed) {
        this.$axios.post(process.env.VUE_APP_API_URL + "/billing/process-cancellation").then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          this.$EventBus.$emit("refreshUser");
          this.$router.push({ name: "BillingSubscription" });
        });
      }
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
    path() {
      return this.$router.currentRoute.fullPath;
    },
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LL");
    }
  },
};
</script>

<style>
</style>